import * as React from 'react';
import {
    email,
    maxLength,
    minLength,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useTranslate
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import palette from "../styles/palette";
import axios from "axios";
import {SectionTitle} from "../components/SectionTitle";
import {CustomToolbar} from "../components/CustomToolbar";

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderRadius: " 0px 0px 5px 5px",
        "& .MuiButton-containedPrimary": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
            borderRadius: "20px",
        }
    },
    titleForm: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottom: '1px solid #0AA5B7',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontWeight: 700,
        color: palette.primary.main,
        fontSize: '1.5rem',
    },
    form: {
        maxWidth: 760,
        marginLeft: theme.spacing(9),
        marginTop: theme.spacing(8),
        border: " 1px solid #0AA5B7",
        borderRadius: "5px",
        "& .MuiCardContent-root": {
            padding: theme.spacing(8),
        },
    },

    email: {
        width: "100%",
    },
    leftGap: {
        marginLeft: theme.spacing(2),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
    },
    text: {
        width: "100%",
        textAlign: "center",
        paddingBottom: theme.spacing(2.5),
        fontSize: "0.875rem",
        fontWeight: "400",
        color: theme.palette.primary.main,
    },
    title: {
        fontSize: '1.125rem',
        fontWeight: 700,
        color: palette.primary.main,
    },
    button: {
        width: '45%',
        height: 40,
        marginBottom: theme.spacing(10.5),
        marginTop: theme.spacing(1.5),
        borderRadius: 5,
        background: "linear-gradient(180deg, #0AA5B7 13.64%, #00929C 128.79%)",
        color: theme.palette.background.default,
        fontFamily: " IBM Plex Sans",
        fontWeight: "bold",
        fontSize: "0.875rem",
        "&:hover": {
            background: "linear-gradient(180deg, #0AA5B7 13.64%, #00929C 128.79%)",
        },
    },

}));

const ForgotPassword = () => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate()

    const handleSubmit = (values) => {
        const request = ({
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: ({'Content-Type': 'application/json;charset=utf-8'}),
            data: values.email.toString(),
        });
        axios(`${window._env_.REACT_APP_API_HOST}` + '/merchant/api/account/reset_password/init', request)
            .then(res => {
                res.status === 200 && redirect('/reset-password-saved');
            })
            .catch(err => {
                err.response.data?.errorCode === "OBJECT_NOT_FOUND" ? redirect('/reset-password-saved') : notify(`Error: ${err?.message}`,{type: 'warning'});
            });

    };
    return (
        <SimpleForm
            save={handleSubmit}
            onSubmit={handleSubmit}
            toolbar={<CustomToolbar label='resetPasswordForm.submit'/>}
            className={classes.form}>
            <Grid className={classes.titleForm}>
                {translate('resetPasswordForm.title')}
            </Grid>
            <Grid className={classes.text}>
                {translate('resetPasswordForm.text')}</Grid>
            <Grid className={classes.wrapper}>
                <SectionTitle
                    className={classes.title}
                    label="resetPasswordForm.email"
                />
                <TextInput
                    source="email"
                    formClassName={classes.email}
                    validate={[
                        required(),
                        email(),
                        minLength(5),
                        maxLength(100)]}
                />
            </Grid>
        </SimpleForm>
    )
};


export default ForgotPassword;