export const passwordMatch = (value, compareWith) => {
  if (value && !compareWith) {
    return "changePasswordForm.requiredPassword";
  }
  if (value && value !== compareWith) {
    return "changePasswordForm.confirmPasswordError";
  }
  return undefined;
};

export const passwordValidation = (value) => {
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,20}$/;
  if (!value) {
    return "changePasswordForm.requiredPassword";
  }
  if (!passwordRegex.test(value)) {
    return "changePasswordForm.passwordCriteria";
  }
  return undefined;
};

export const passwordNoValidation = (value) => {
  if (value && value.length > 0) {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,20}$/;
    if (!passwordRegex.test(value)) {
      return "changePasswordForm.passwordCriteria";
    }
  }

  return undefined;
};

export const validateNoSpecialChars = (value) => {
  if (/[^\w\s]/.test(value)) {
    return "changePasswordForm.noSpecialChars";
  }
  return undefined;
};

export const sanitizeAndValidate = (value) => {
  const sanitizedValue = sanitizeInput(value);
  if (sanitizedValue !== value) {
    return "Special characters were removed from the input.";
  }
  return undefined;
};
