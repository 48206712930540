import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";

let customEnglishMessages: TranslationMessages;
customEnglishMessages = {
    ...englishMessages,
    show: "Show",
    infoPage: {
        accountUpdated: "Account updated",
        checkEmail: "Check your email to set a new password",
        registred: "Registration saved",
        checkEmailApproved:
            "You will receive an email after your account has been approved",
    },
    resetPasswordForm: {
        title: "Reset your password",
        text: "Enter the-email address you used to register",
        email: "E-mail",
        submit: "Reset password",
    },
    activationNote: {
        title: "Activation",
        success:
            "The merchant has been activated. An email has been sent to him for connection. ",
        error: "The merchant could not be activated. Please use the registration form to sign up.",
    },
    retypePasswordForm: {
        title: "Reset password",
        text: "Choose a new password",
        newPassword: "New password",
        placeholderEnterYourPassword: " Enter your password here...",
        placeholderConfirmYourPassword: " Confirm your new password here...",
        retypePassword: "Retype password",
        submit: "Validate new password",
    },
    resetPasswordApply: {
        title: "Reset your password",
        success: "Your password has been reset. Please authenticate",
        error: "Your password couldn't be reset. Remember a password request is only valid for 24 hours.",
    },
    title: {
        edit: "EDIT",
    },
    loginPage: {
        stayLoggedInLabel: "Stay logged in",
        createAccountButton: "Create an account",
        forgotPasswordButton: "Did you forgot your password?",
    },
    apiForm: {
        apiSettings: "API Settings",
        text: "You can manage your API account directly in the",
        link: " API Manager.",
        sectionTitle: "Callback URLs",
        fieldReturnUrl: "Return URL",
        fieldInstantNoteUrl: "Instant notification URL",
        toggleGet: "Use GET method to send notifications",
        enter_the_return_url_here: "Enter the return URL here",
        enter_your_notification_url_here: "Enter your notification URL here",
        global_return_url: "Global Return URL",
        instant_notification_url: "Instant Notification URL",
        get_request: "GET request",
        put_request: "PUT request",
        global: {
            the_return_url_is_being_called_text: "The Return URL is being called by Smobilpay for e-commerce after a payment has been completed on the payment page and the system is directing the user back to your application.",
            you_can_define_a_global_url_text: "You can define a global URL here, which will always be used, unless you provide a specific URL in the initial payment order placement request.",
            please_specify_how_you_would_text: "Please specify how you would like us to call your system during the return redirect:",
            we_shall_direct_users_back_text: "We shall direct users back to your store via a GET request. The reference ID as well as the payment status will be added as GET parameters to the Return URL. Use this when your system does not support individualized return URLs at the time of order creation and you need us to provide you with order information in the callback.",
            enhanced_get_request: "Enhanced GET request",
            we_shall_direct_users_back_second_text: "We shall direct users back to your store via a simple GET request. We shall send a GET request to the URL you have provided as a Return URL (either globally here or in the Order Request) without modifying the URL or adding any additional parameters. Use this when your system already generates a unique Return URL with a full set of identifiers.",
            simple_get_request: "Simple GET request",
            please_provide_a_return_url:'Please provide a return URL'
        },
        instant: {
            provide_a_url_for_immediate: "Provide a URL for immediate notifications on order state changes.",
            this_url_will_be_used: "This URL will be used unless a different one is provided with the payment order request.",
            provide_a_webhook_here_text: "Provide a webhook here, in order to instantly get notified the moment an order changes state.",
            you_can_define_a_global_text: "You can define a global URL here, which will always be used, unless you provide a specific URL in the initial payment order placement request.",
            please_specify_how_you_would_text: "Please specify how you would like us to notify your system:",
            we_shall_send_a_put_request_text: "A PUT request will be sent to the URL with the reference added as a path variable. The payment status will be in the request body.",
            we_shall_send_a_get_request_text: "A GET request will be sent to the URL with the reference and status added as a path variable.",
            please_provide_a_notification_url:'Please provide a notification URL'
        }
    },
    editProfileMerchant: {
        title: "My Profile",
        basicInfo: " 1. Basic Information",
        companyInfo: "2. Company Profile",
        contactInfo: "3. Contact Information",
        changePassword: "4. Change Password",
        paymentInfo: "5. Payout Details",
        supportInfo: "6. Support Information",
        enable: "Enable",
        disable: "Disable",
        yes: "Yes",
        no: "No",
    },
    changePasswordForm: {
        title: "My Profile",
        requiredPassword: "Password is required",
        passwordCriteria: "Password must be between 8-20 characters, contain at least one letter, one number, and one special character.",
        changePassword: "Change Password",
        currentPassword: "Current Password",
        newPassword: "New Password",
        confirmPassword: "Confirm Password",
        confirmPasswordError: "Passwords do not match",
        newPasswordError:
            "New password must be different from current password",
        wrongCurrentPassword: "The current password is incorrect",
        noSpecialChars: "Special characters are not allowed"
    },
    menuApp: {
        editPassword: "Edit Password",
        apiSettings: "API Settings",
        logins: "Login",
    },
    sideBarTitles: {
        transactions: "All Transactions",
        merchants: "Merchants",
        commissions: "Commissions",
        payment: "Payment",
        payout: "Payout",
        payouts: "Payouts",
        editProfile: "Edit Profile",
    },
    resources: {
        register: {
            fields: {
                userLogin: "Username",
                companyName: "Company name",
                email: "Email",
                contactPersonFirstName: "First name",
                contactPersonLastName: "Last name",
                address: {
                    address: "Address",
                    countryIso3Code: "Country code",
                    state: "State/Province",
                    city: "City",
                    postalCode: "Zip/Postcode",
                },
                currency: "Currency",
                phoneNumber: "Phone Number",
                faxNumber: "Fax Number",
                password: "Password",
                confirmPassword: "Confirm Password",
                receiveNewsletter: "Receive Newsletters",
                yes: "Yes",
                no: "No",
            },
            phoneError: "The phone number is invalid",
            userLoginError: "The username is invalid",
            userLoginInUseError: "The username already in use",
            emailInUseError: "The e-mail address already in use",
            companyNameInUseError: "The company's name already in use",
            title: "Registration",
            basicInfo: " 1. Basic Information",
            companyInfo: "2. Company Profile",
            contactInfo: "3. Contact Information",
            submitButton: "SUBMIT AND CONTINUE",
            passwordMatchError: "Passwords do not match",
            usernameError:
                "Your login can only contain lower-case letters and digits.",
        },
        transactions: {
            fields: {
                orderStatus: "Order status",
                date: "Created Date",
                processedDate: "Processed Date",
                merchant: "Merchant Name",
                merchantId: "Merchant ID",
                provider: "Provider",
                accountN: "Account Number",
                accountName: "Account Name",
                orderN: "Order#",
                amount: "Amount",
                status: "Order Status",
                externalRefId: "Ext Order ID",
            },
            orderCard: {
                status: "Order Status",
                details: "Details",
                orderExternal: "Ext Order ID",
                amount: "Amount",
                ID: "ID",
                serviceCharge: "Service Charge",
                totalAmount: "Total Amount",
            },
            dateAfter: "Date after",
            dateBefore: "Date before",
            orderExternal: "External Order ID",
            amountAbove: "Amount Above",
            merchantId: "Merchant ID",
            status: "Status",
            paymentProvider: "Payment Provider",
            from: "From",
            to: "To",
        },
        status: {
            CONFIRMED: "PAID",
            FAILED: "FAILED",
            CANCELED: "CANCELED",
            IN_PROGRESS: "IN PROGRESS",
            CREATED: "CREATED",
            INITIALISED: "INITIALISED",
            EXPIRED: 'EXPIRED',
        },
        activated: {
            true: "Yes",
            false: "No",
        },
        "merchant/account": {
            fields: {
                website: "Website",
                companyName: "Company name",
                email: "Email",
                profile: "EDIT",
                deactivation: "DEACTIVATE",
                activation: "ACTIVATE",
                contactPersonFirstName: "First name",
                contactPersonLastName: "Last name",
                address: {
                    address: "Address",
                    countryIso3Code: "Country code",
                    state: "State/Province",
                    city: "City",
                    postalCode: "Zip/Postcode",
                },
                currency: "Currency",
                phoneNumber: "Phone Number",
                faxNumber: "Fax Number",
                supportEmail: "Support Email",
                langKey: "Language",
                password: "Password",
                confirmPassword: "Confirm Password",
                receiveNewsletter: "Receive Newsletters",
                payoutDetails: {
                    type: "Type",
                    acquirer: "Acquirer",
                    accountNumber: "Account Number",
                    accountName: "Account Name",
                    customerReference: "Customer Reference",
                },
            },
        },
        merchants: {
            fields: {
                login: "User",
                activated: "Activated",
                website: "Website",
                companyName: "Company name",
                countryIso3Code: "Country code",
                email: "Email",
                profile: "EDIT",
                deactivation: "deactivate",
                activation: "activate",
                deactive: "deactivate",
                active: "activate",
                merchant: "merchant",
                title: "Update View Count",
                confirmation: "Are you sure you want to",
                contactPersonFirstName: "First name",
                contactPersonLastName: "Last name",
                address: "Address",
                state: "State/Province",
                city: "City",
                postalCode: "Zip/Postcode",
                currency: "Currency",
                phoneNumber: "Phone Number",
                faxNumber: "Fax Number",
                supportEmail: "Support Email",
                payoutType: "Type",
                payoutAcquirer: "Acquirer",
                payoutAccountNumber: "Account Number",
                payoutAccountName: "Account Name",
                payoutCustomerReference: "Customer Reference",
                countryCode: "Country code",
            },
            forms: {
                editMerchant: "Edit Merchant",
                viewDetails: `View Details`,
                editPayment: `Edit Payment`,
                editPayout: `Edit Payout`,
                additionalFees: `Additional fees`,
                themes: "Themes",
                editPayoutMethod: "Edit Payout Method",
                basicInfo: " 1. Basic Information",
                companyInfo: "2. Company Profile",
                contactInfo: "3. Contact Information",
                paymentInfo: "4. Payout Details",
                payments: {
                    provider: "Provider",
                    active: "Active",
                    commissionPreset: "Commissions Preset",
                    enable: "Enabled",
                    disable: "Disabled",
                },
                payouts: {
                    acquier: "Acquirer",
                    commissionPreset: "Commissions Preset",
                    providerName: "Provider Name",
                    friendlyName: "Friendly Name",
                    currency: "Currency",
                    countries: "Countries",
                    active: "Active",
                    applicableFees: "Applicable Fees",
                    uploadLogo: "Upload logo",
                    uploadFormatText: "Recommented size at 188x40 px for better results",
                    clickToSelectFile: "Click to select a file",
                    supportedFilesText: "supported extensions : jpeg .png",
                    previewImage: "Preview Image",
                    configureTheme: "Configure theme",
                    configureThemeText: "Change the color of some UI components on the page (card, table, button)",
                    headerBackgroundColor: "Header Background Color",
                    primaryColor: "Primary Color",
                    secondaryColor: "Secondary Color",
                    pickColor: "Pick a color",
                },
                fees:{
                    enableLineItem: "Enable Line item",
                    enableItemDescription: "Activate this feature to include additional line items, such as taxes and fees, in the total cart calculation",
                }
            },
        },
        payments: {
            fields: {
                name: "Name",
                currency: "Currency",
                description: "Description",
                fees: "Fees",
                min: "Min",
                max: "Max",
                fixed: "Fixed",
                percentage: "Percentage",
            },
            grid: {
                min: "Min",
                max: "Max",
                fixed: "Fixed",
                percentage: "Percentage",
            },
            editTitle: "Edit Preset",
            createTitle: "Create Preset",
            addNewPreset: "Add new preset",
            route: "Commissions/Payment",
        },
        payout: {
            fields: {
                name: "Name",
            },
            route: "Commissions/Payout",
        },
        payouts: {
            currentBalance: "Current Balance: ",
            requestPayout: "Request Payout",
            dialog: {
                trigger: {
                    title: "Approve payout ",
                    content: "Are you sure you want to approve this payout?",
                },
                decline: {
                    title: "Decline payout",
                    content: "Are you sure you want to reject this payout?",
                },
            },
            fields: {
                name: "Name",
                externalReference: "External Reference",
                date: "Date",
                merchantName: "Merchant",
                totalAmount: "Amount",
                fee: "Fee",
                comment: "Comment",
                payoutStatus: "Payout Status",
                title: "Payout",
            },
            forms: {
                title: "Payout Request Information",
                charge: "Charge fee",
                amount: "1. Amount",
                totalAmount: "Total Amount",
                comment: "2. Comment",
                triggerButton: "Trigger Payout",
                submitButton: "Submit",
                titleCreate: "My Payout Request",
                cancelButton: "Cancel",
                declineButton: "Decline",
                grid: {
                    title: "Payout Details",
                    payoutType: "Type of Payout",
                    payoutAcquirer: "Acquirer",
                    payoutAccountNumber: "Account Number",
                    payoutAccountName: "Account Name",
                },
                cardEdit: {
                    status: "Payout Status",
                    details: "Detail information",
                    externalReference: "External Reference ID",
                    payoutDate: "Payout Date",
                    currency: "Currency",
                    merchant: "Merchant Name",
                    customerReference: "Customer Reference",
                },
                cardCreate: {
                    currentBalance: "Current Balance",
                    details: "Detail information",
                    merchantId: "Merchant ID",
                    phoneNumber: "Phone Number",
                    address: "Address",
                    region: "Province/City/Country",
                    email: "Email",
                },
            },
            payoutStatus: {
                PENDING: "Pending",
                SUCCESSFUL: "Successful",
                FAILED: "Failed",
            },
        },
    },
};

export default customEnglishMessages;
